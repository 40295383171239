// MOBILE STYLES BELOW *****************************************************************************************
.nav{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.navItems{
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  transition: all .2s ease-in-out;

  &__item{
    display: flex;
    text-align: center;
    width: fit-content;
    font-size: 14px;
    letter-spacing: 1.75px;
    cursor: pointer;
    padding: 8px 30px 0 30px;
    border: 2px solid transparent;
    font-weight: 900;
    color: #3D2A77;
  }
  &__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
    margin-top: -30px;
  }
}

.nav-logo{
  display: flex;
}
.logo{
  height: 50px;
  width: 71px;
}

.nav-text{
  margin-left: 60px;
  color: #3D2A77;
  font-size: 16px;
  font-weight: 400;
  max-width: 360px;
  &-name{
    color: #6F44F5;
    text-decoration: underline;
    &:hover{
      cursor: pointer;
    }
  }
}
.navContainer{
  display: flex;
  flex-direction: column;
}

// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 768px) and (max-width: 1024px) {

}

// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 769px) {

}
