@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

html{
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
}
html,body {
  margin:0;
  padding:0;
  width: 100%;
  overflow-x:hidden;
}
* {
  box-sizing:border-box;
  margin: 0;
  padding: 0;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  z-index: 4;
  color: #303350;
}
a{
  text-decoration: none;
  color: inherit;
}


.body{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #F8F8F8;
}
