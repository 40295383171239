@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

html{
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
}
html,body {
    margin:0;
    padding:0;
    width: 100%;
    overflow-x:hidden;
}
* {
    box-sizing:border-box;
    margin: 0;
    padding: 0;
    border: none;
    font-family: 'roc-grotesk';
    z-index: 4;
    color: #303350;
}
a{
    text-decoration: none;
    color: inherit;
}

.nav{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.nav-items{
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    transition: all .2s ease-in-out;
}

.nav-item, .nav-item-selected{
    display: flex;
    text-align: center;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.75px;
    /*margin: 30px;*/
    cursor: pointer;
    padding: 20px 25px;
    border: 2px solid transparent;
    color: #6F44F5;

}
.nav-item-selected{
    border: 2px solid #3F4CF9;
    padding: 20px 25px;
    border-radius: 10px;
    color: #3F4CF9;
    background-color: white;

}

.nav-item:hover{
    /*transform: scale(1.02);*/
    opacity: .8;
    transition: all .1s ease-in;
}
.nav-item:active{
    /*transform: scale(.98);*/
    opacity: .8;
    transition: all .2s ease-in;
}




/*.nav-items:before{*/
/*    content: '';*/
/*    position: fixed;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    right: -30px;*/
/*    top: -200px;*/
/*    z-index: 2 !important;*/
/*    display: block;*/
/*    border-radius: 100%;*/
/*    border: solid 1px #000000;*/
/*    opacity: .10;*/
/*}*/



.home-container{
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    padding: 30px;
}


.footer{
    height: 150px;
    width: 100%;
    border:1px solid black;
    border-radius: 10px;

}
.animation-1{
    z-index: 2 !important;
    display: block;
    animation: move 20s ease-in-out infinite;
    background-color: cornflowerblue;
    --size: 700px;
    position: fixed;
    left: calc(var(--size) / 3 * -1);
    top: calc(var(--size) / 3 * -1);
    width: var(--size);
    height: var(--size);
    mix-blend-mode: soft-light;
    border-radius: var(--size);
    filter: blur(calc(var(--size) / 6));
    will-change: transform;
    overflow-x: hidden;
    max-width: 100%;
}
.animation-2{
    z-index: 2 !important;
    display: block;
    animation: moveDown 20s ease-in-out infinite;
    background-color: darkorchid;
    --size: 400px;
    position: fixed;
    left: calc(var(--size) / 3 * -1);
    top: calc(var(--size) / 3 * -1);
    width: var(--size);
    height: var(--size);
    mix-blend-mode: soft-light;
    border-radius: var(--size);
    filter: blur(calc(var(--size) / 6));
    will-change: transform;
    overflow-x: hidden;
    max-width: 100%;

}

.animation-3{
    z-index: 2 !important;
    display: block;
    animation: move 10s ease-in-out infinite;
    background-color: orangered;
    --size: 500px;
    position: fixed;
    left: calc(var(--size) / 2 * -1);
    top: calc(var(--size) / 2 * -1);
    width: var(--size);
    height: var(--size);
    mix-blend-mode: soft-light;
    border-radius: var(--size);
    filter: blur(calc(var(--size) / 6));
    will-change: transform;
    overflow-x: hidden;
    max-width: 100%;
}
@keyframes move {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes moveDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(300px);
    }
    100% {
        transform: translateY(0);
    }
}

@media only screen and (min-width: 1025px) {
    .home-container{
        width: 100%;
        padding: 60px;
    }
    .project{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: fit-content;
        width: 100%;
        flex: 1;
    }

    /*.nav-items:before{*/
    /*    content: '';*/
    /*    position: absolute;*/
    /*    width: 600px;*/
    /*    height: 600px;*/
    /*    right: -30px;*/
    /*    top: -200px;*/
    /*    z-index: 3 !important;*/
    /*    display: block;*/
    /*    border-radius: 100%;*/
    /*    border: solid 1px #000000;*/
    /*    opacity: .10;*/
    /*}*/
}


/*tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
    .home-container{
        width: 100%;
        padding: 60px;
    }
    .project{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: fit-content;
        width: 100%;
        flex: 1;
    }

    /*.nav-items:before{*/
    /*    content: '';*/
    /*    position: fixed;*/
    /*    width: 600px;*/
    /*    height: 600px;*/
    /*    right: -30px;*/
    /*    top: -200px;*/
    /*    z-index: 2 !important;*/
    /*    display: block;*/
    /*    border-radius: 100%;*/
    /*    border: solid 1px #000000;*/
    /*    opacity: .10;*/
    /*}*/
}

/*about me section*/
.aboutMe{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    width: 100%;
    flex: 1;
    padding: 0 228px;

    /*margin:110px 0 0 0;*/
}
.aboutMe-info{
    display: flex;
    align-items: flex-start
}
.aboutMe-image{
    margin-right: 68px;
}
.aboutMe-greeting{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 10px;
}
.aboutMe-sub{
    font-size: 24px;
    color: #303350;
    margin-bottom: 30px;
    line-height: 32px;
}
.aboutMe-description{
    font-size: 16px;
    line-height: 26px;
    color: #303350;
}
.aboutMe-details{
    display: flex;
    flex-direction: row;
    color: #303350;
    margin-top: 60px;
}
.aboutMe-highlight-header{
    background-color: #C2C5FF;
    font-weight: 700;
    font-size: 24px;
    width: fit-content;
    padding: 0 4px;
    margin-bottom: 30px;
    line-height: 28px;
}
.aboutMe-career{
    display: flex;
    flex-direction: column;
}

ul {
    list-style-type: none;
}

li:before{
    content: "\26AC";
    color: #3F4CF9;
    font-weight: 800;
    display: inline-block;
    width: 30px;
    margin-left: -30px;
}
li{
    padding-bottom: 102px;
}
li:last-child{
    padding-bottom: unset;
}
.aboutMe-bullets{
    margin-top: 65px;
}
.aboutMe-bullets-line{
    position: absolute;
    width: 1px;
    height: 120px;
    margin-top: 16px;
    margin-left: -26px;
    background-color: #3F4CF9;
}
.aboutMe-career-container{
    margin-bottom: 40px;
}
.aboutMe-year{
    color: #3F4CF9;
    font-size: 24px;
    padding-bottom: 10px;
}

.aboutMe-company{
    font-size: 16px;
    font-weight: 800;
    color: #303350;
}

.aboutMe-title{
    color: #303350;
    font-size: 16px;
}
.aboutMe-flex-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.aboutMe-skills-list{
    display: flex;
    flex-direction: column;
}
.aboutMe-skills-list:first-child{
    padding-right: 40px;
}

.aboutMe-skills-list-item{
    color: #666881;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
}
.aboutMe-button{
    margin-top: 60px;
    border: solid 2px #3F4CF9;
    color:#3F4CF9;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 800;
    font-size: 14px;
    padding: 12px 16px;
}



