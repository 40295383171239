// MOBILE STYLES BELOW *****************************************************************************************
.about{
  margin: 120px 0 0 0;
  &__top{
    font-size: 60px;
    line-height: 68px;
    font-weight: 500;
    padding-bottom: 60px;
    color: #3D2A77;
    margin: 0 120px;
  }
  &__card{
    font-size: 40px;
    line-height: 80px;
    padding: 120px;
    background-color: #E4E5FC;
    border-radius: 50px;
    color: #3D2A77;
  }
  &__container{
    display: flex;
    text-align: center;
    &_text{
      font-family: roc-grotesk-wide;
      padding: 100px;
      font-size: 26px;
      font-weight: bold;
      line-height: 50px;
      color: #3D2A77;
    }
  }
}
.nebs{
  position: relative;
  text-decoration: underline;
  font-size: 26px;
  font-weight: bold;
  line-height: 50px;
  color: #3D2A77;
  font-family: roc-grotesk-wide;
  &:hover{
    cursor: pointer;
  }
  &__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &_image{
      position: absolute;
      max-width: calc(100% - 40px);
      width: fit-content;
      z-index: 1000;
    }
  }
}

.info{
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  &__title{
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #C2C5FF;
    padding: 4px;
    font-size: 26px;
    height: 34px;
    line-height: 16px;
    font-family: "roc-grotesk-wide";
    width: fit-content;
    margin-bottom: 60px;
  }
  &__career{
    flex: 1;
    min-width: 40%;
    max-width: 50%;
    margin: 0 60px 60px 0;
    padding: 60px;
    background-color: white;
    border-radius: 50px;

    &_title{

    }
    &_job{
      padding-top: 40px;

      &-year{
        font-size: 24px;
        font-weight: 800;
        color: #3D2A77;
      }
      &-name{
        font-size: 18px;
        font-weight: 500;
        color: #3D2A77;
      }
      &-title{
        color: #3D2A77;
      }
    }
  }
  &__skills{
    margin: 0 0 60px 0;
    padding: 60px;
    background-color: white;
    border-radius: 50px;
    flex: 1;
    min-width: 320px;
    &_name{
      font-size: 16px;
      color: #3D2A77;
      line-height: 30px;
    }
  }
  &__programs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 60px;
    &_card{
      padding: 60px;
      background-color: white;
      border-radius: 50px;
      flex: 1;
      &-name{
        font-size: 16px;
        color: #3D2A77;
        line-height: 30px;
      }
    }
    &_download{
      display: flex;
      border: 1px solid #E4E5FC;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 70px 39px;
      border-radius: 50px;
      margin: 60px 0;
      min-width: 320px;
      &-title{
        font-family: 'roc-grotesk-wide';
        color: #3D2A77;
        font-size: 28px;
      }
      &-button{
        height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 18px;
        font-weight: 800;
        border-radius: 30px;
        background-color: white;
        color: #6F44F5;
        font-family: 'roc-grotesk-wide';
        margin-top: 40px;
        text-transform: uppercase;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}

.footer-linkedin{
  color: #6F44F5;
  text-decoration: underline;
  font-family: 'roc-grotesk-wide';
  margin: 6px 0 6px 20px;
  &:hover{
    cursor: pointer;
  }
}
.footer{
  &-wrapper{
    padding-top: 60px;
    display: flex;
    align-items: center;
  }
  &-text{
    display: flex;
    align-items: flex-end;
    color: #9599D7;
    font-size: 14px;
    height: 22px;
    padding-top: 10px;
  }
}


// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 768px) and (max-width: 1386px) {
.info {
  &__programs {
    margin-left: 0;

    flex-direction: row;
    flex: 1;
    &_card{
      max-width: calc(50% - 30px);
    }
    &_download{
      margin: 0;
      margin-left: 60px;

    }
    }
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .about {
    &__top{
      font-size: 40px;
      margin: 0;
    }
    &__card{
      padding: 60px;
      font-size: 28px;
      line-height: 58px;
    }
    &__container {
      text-align: unset;
      &_text {
        padding: 60px 0;
        font-size: 18px;
      }
    }
  }
  .nebs{
    font-size: 18px;
  }

}
@media (min-width: 320px) and (max-width: 768px) {
  .info__skills{
    &_name{
      width: 50%;
    }
    &_container{
      display: flex;
      flex-wrap: wrap;
    }
  }

  .about {
    margin-top: 60px;
    &__card{
      padding: 30px;
      font-size: 16px;
      line-height: 36px;
    }
    &__top{
      font-size: 32px;
      margin: 0;
      line-height: 52px;
    }
    &__container {
      text-align: unset;
      &_text {
        padding: 60px 0;
        font-size: 18px;
      }
    }
  }
  .nebs{
    font-size: 18px;
  }
  .info{
    flex-direction: column;
    &__title{
      margin-bottom: 40px;
    }
    &__career{
      margin: 0 0 40px 0;
      min-width: 100%;
      max-width: 100%;
    }
    &__skills{
      margin: 0 0 40px 0;
    }
    &__programs{
      margin: 0;
      &_download{
        margin: 40px 0 0 0;
      }
    }
  }


}
@media (min-width: 0) and (max-width: 321px) {
  .about {
    margin-top: 60px;
    &__card {
      padding: 30px;
      font-size: 16px;
      line-height: 36px;
    }

    &__top {
      font-size: 22px;
      margin: 0;
      line-height: 36px;
    }

    &__container {
      text-align: unset;

      &_text {
        padding:24px 0 40px 0;
        font-size: 18px;
        line-height: 34px;
      }
    }
  }
  .nebs {
    font-size: 18px;
    line-height: 34px;
  }
  .info {
    flex-direction: column;

    &__title {
      justify-content: center;
      margin-bottom: 40px;
      text-align: center;
    }

    &__career {
      margin: 0 0 40px 0;
      min-width: 100%;
      max-width: 100%;
      padding: 30px;
    }

    &__skills {
      margin: 0 0 40px 0;
      padding: 30px;
      min-width: 100%;
      max-width: 100%;
    }

    &__programs {
      margin: 0;
      min-width: 100%;
      max-width: 100%;
      &_card{
        padding: 30px;
      }

      &_download {
        min-width: 100%;
        max-width: 100%;
        margin: 40px 0 0 0;
        padding: 30px;
      }
    }
  }
}
// button
.wrapper {
  padding: 1.5rem 0;
  filter: url('#goo');
}

.button {
  display: inline-block;
  text-align: center;
  background: var(--color);
  color: var(--bg);
  font-weight: bold;
  padding: 1.18em 1.32em 1.03em;
  line-height: 1;
  border-radius: 1em;
  position: relative;
  min-width: 8.23em;
  text-decoration: none;
  font-family: var(--font);
  font-size: 1.25rem;
}

.button:before,
.button:after {
  width: 4.4em;
  height: 2.95em;
  position: absolute;
  content: "";
  display: inline-block;
  background: var(--color);
  border-radius: 50%;
  transition: transform 1s ease;
  transform: scale(0);
  z-index: -1;
}

.button:before {
  top: -25%;
  left: 20%;
}

.button:after {
  bottom: -25%;
  right: 20%;
}

.button:hover:before,
.button:hover:after {
  transform: none;
}
