// DESKTOP STYLES BELOW *****************************************************************************************
.project{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  flex: 1;
}
.project__section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #E4E5FC;
  border-radius: 50px;
  flex-basis: calc(50% - 30px);
  height: 565px;
  margin-bottom: 60px;
}

.header{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin: 120px 0;
}
.header-main{
  color: #3D2A77;
  word-break: break-word;
  font-size: 60px;
  font-weight: bold;
  line-height: 64px;
}
.header-main-bottom{
  color: #3D2A77;
  word-break: break-word;
  font-size: 60px;
  font-weight: 600;

  line-height: 64px;
}
.header-sub{
  font-size: 18px;
  font-weight: 600;
}

.designer{
  color: #6F44F5;
}

.projectHover{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: inherit;
  width: 100%;
  border-radius: 50px;
  flex-direction: column;
  background-color: #E4E5FC;
  color: #3D2A77;
  border: 2px solid #E4E5FC;
  padding: 60px;
  &__title{
    font-family: "roc-grotesk";
    line-height: 60px;
    font-size: 32px;
    font-weight: 700;
    color:#3D2A77;
  }
  &__sub{
    &--text{
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: #C2C5FF;
      padding: 4px;
      width: fit-content;
      font-size: 18px;
      height: 34px;
      line-height: 16px;
      margin-bottom: 22px;
      font-family: "roc-grotesk-wide";
      color:#3D2A77;
    }
  }
  &__details{
    font-size: 18px;
    line-height: 24px;
    color: #3D2A77;
  }
  &__button{
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    border-radius: 30px;
    background-color: white;
    color: #6F44F5;
    font-family: 'roc-grotesk-wide';
    margin-top: 60px;
    &:hover{
      cursor: pointer;
    }
  }
}
.nav-text-alt{
  display: none;
}


// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 768px) and (max-width: 1024px) {
  .home-container{
    padding: 40px;
  }

  .project__section{
    height: 422px;
    margin-bottom: 60px;
    &-image{
      height: 411px;
      width: 238px;
    }
  }
  .nav-text-alt{
    display: none;
  }
  .header{
    margin: 100px 0;
  }
  .projectHover__title{
    padding-bottom: 20px;
    line-height: 34px;
  }

}

@media (min-width: 0) and (max-width: 767px) {
  .home-container{
    padding: 30px;
  }
  .projectHover__title{
    padding-bottom: 0;
  }

  .project__section{
    min-height: 280px;
    height: 422px;
    width: 100%;
    flex-basis: unset;
    margin-bottom: 30px;
    &-image{
      height: 400px;
    }
  }
  .header{
    margin: 40px 0;
  }
  .nav-text{
    display: unset;
    margin-left: 30px !important;
  }
  .nav-text-alt{
    display: none;
    color: #3D2A77;
    padding: 30px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    max-width: 360px;
  }
  .navItems__item{
    padding: 8px 0 0 0 !important;
  }
  .navItems__container{
    padding-left: 20px;
    margin-top: 0!important;
    &:first-child{
      padding-right: 20px;
      padding-left: 0;
    }
  }
  .projectHover__button{
    min-height: 64px;
    margin-top: 20px;
  }
  .projectHover{
    padding: 30px;
    &__title{
      line-height: unset;
    }
  }
}
@media (min-width: 0) and (max-width: 520px) {
  .nav-text{
    display: none;
    margin-left: 30px !important;
  }
  .nav-text-alt{
    display: unset;
    color: #3D2A77;
    padding: 30px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    max-width: 360px;
  }
}

@media (min-width: 0) and (max-width: 320px) {
  .header-main{
    word-break: break-word;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
  }
  .header-main-bottom{
    word-break: break-word;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
  .projectHover__title{
    line-height: 28px;
  }
  .navItems__container{
    padding-left: 0;
    margin-top: 0!important;
    &:first-child{
      padding-right: 20px;
      padding-left: 0;
    }
  }
  .logo{
    height: 30px !important;
  }
}

